import { createStore } from 'vuex';
import { parseISO } from 'date-fns';

export const store = createStore({
    state: {
        data: [],
        isLoaded: false
    },
    getters: {
        parishes: (state, getters) => {
            return state.data.map((itm) => ({ id: itm.ParishId, parish: itm.ParishName, code: itm.ParishCode, hasRecentTaxChange: getters.parishHasRecentTaxChange(itm) }));
        },
        getParishById: (state) => (parishId) => {
            let idx = state.data.findIndex((itm) => itm.ParishId == parishId);
            if (idx == -1)
                return null;

            return state.data[idx];
        },
        getDomicilesByParishId: (state, getters) => (parishId) => {
            let p = getters.getParishById(parishId) 
            return p.Domiciles
                .map((itm) => ({ id: itm.DomicileId, domicile: itm.DomicileName, code: itm.DomicileCode, parishCode: p.ParishCode, hasRecentTaxChange: getters.domicileHasRecentTaxChange(itm) }))
                    .sort((a, b) => a.domicile.localeCompare(b.domicile));
        },
        parishHasRecentTaxChange: (state,getters) => (parish) => {
            for (let i = 0; i < parish.Domiciles.length; i++) {
                let does = getters.domicileHasRecentTaxChange(parish.Domiciles[i]);
                if (does)
                    return does;
            }

            return false;
        },
        domicileHasRecentTaxChange: () => (domicile) => {
            let sixMonthsAgo = new Date();
            sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
            return domicile.TaxRates.filter((t) => parseISO(t.TaxDate) >= sixMonthsAgo).length > 0;
        }, 
        getParishByParishCode: (state) => (parishCode) => {
            let idx = state.data.findIndex((itm) => itm.ParishCode == parishCode);
            if (idx == -1)
                return null;

            return state.data[idx];
        }
    },
    mutations: {
    },
    actions: {
        loadStore: (context) => {
            let qs = new Date().getTime();
            let url = `/data.json?q=${qs}`;
            let xhr = new XMLHttpRequest();
            xhr.open('GET', url);
            xhr.onload = function () {
                if (this.status >= 200 && this.status < 300) {
                    context.state.data = JSON.parse(xhr.response).Taxes;
                    setTimeout(function () {
                        context.state.isLoaded = true;
                    }, 300);
                }
            };
            xhr.onerror = function () {

            };
            xhr.send();
        }
    },
    modules: {
    }
})