<template>
    <footer class="footer bg-black mt-auto" id="FooterTextLinksOnly">
        <div class="py-4">
            <small class="d-block text-primary-10 text-center font-italic">The content of this site does not include applicable State Taxes.</small>
            <div class="container-fluid" role="navigation" aria-label="Footer navigation" id="FooterNavigation">
                <div class="d-flex flex-wrap justify-content-center" role="navigation">
                    <a class="btn btn-link text-white border-0 rounded-0" href="/">Tax Table</a>
                    <a class="btn btn-link text-white border-0 rounded-0" href="https://offices.omv.la.gov">Office List</a>
                    <a class="btn btn-link text-white border-0 rounded-0" href="https://expresslane.org">Express Lane</a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'AppFooter'
    }
</script>
