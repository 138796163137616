import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/parish/:id',
    name: 'Parish',    
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "parish" */ '../views/Parish.vue')
  },
  {
    path: '/domicile/:id',
    name: 'Domicile',
    props: true,
    component: () => import(/* webpackChunkName: "domicile" */ '../views/Domicile.vue'),

  },
  {
      path: '/:pathMatch(.*)*',
      name: 'Not Found',
      component: () => import(/* webpackChunkName: "notfound" */ '../views/errors/NotFound.vue'),
   }
]

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {                
      let page = document.querySelector(".page-content");
      if (page != null) {            
          page.scrollTo(0, 0);
      }        
  }
})
