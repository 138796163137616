<template>    
    <div class="page-wrapper agency-theme toggled">        
        <div id="app" class="wrapper-main justify-content-center d-flex flex-column">
            <AppHeader />
            <div v-if="!isLoaded" class="loading-animation-wrapper">
                <div class="loading-animation">
                    <div class="loading-animation-content">
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div v-else class="page-content">                
                <router-view />
            </div>
            <AppFooter />
        </div>
        <a id="ScrollToTop" href="#main" class="btn btn-accent" role="button">
            <span class="fas fa-arrow-up"></span>
        </a>
    </div>     
</template>

<script>
import AppHeader from './components/Header.vue';
import AppFooter from './components/Footer.vue';

    export default {
        components: {
            AppHeader,
            AppFooter
        },     
        computed: {
            isLoaded: function () {
                return this.$store.state.isLoaded;
            }
        },
        beforeCreate: function () {
            this.$store.dispatch("loadStore");
        }
    }
</script>
