import { createApp } from 'vue';
import { router } from './router';
import { store } from './store';
import App from './App.vue';
import titleMixin from './mixins/titleMixin';

import '../public/css/public-sans.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@la-ots/pelican/dist/css/pelican.css';
import '@la-ots/pelican/dist/js/pelican.bundle.js';

const app = createApp({
    extends: App,
    mixins: [titleMixin]
});

app.use(router);
app.use(store);

app.mount('#app');
