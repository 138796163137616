<template>
  <header class="bg-primary text-white py-5">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <h1 class="h4 text-white">
            <router-link to="/" class="text-decoration-none text-white text-center w-100 d-inline-block">
              <span>Parish &amp; Municipality Tax Table</span>
            </router-link>
          </h1>
          <small class="d-block text-primary-10 text-center font-italic">The content of this site does not include applicable State Taxes.</small>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
    export default {
        name: 'AppHeader'
    }
</script>
