<template>
    <main id="main" class="main px-3 px-md-20 container-xl" role="main">
        <domicile-search></domicile-search>
        <hr class="major">
        <jurisdiction-search></jurisdiction-search>
        <hr class="major"/>
        <recent-tax-changes></recent-tax-changes>
        <hr class="major">
        <parish-list></parish-list>
    </main>
</template>

<script>
    // @ is an alias to /src
    import { defineAsyncComponent } from 'vue';

    export default {
        name: 'AppHome',
        title: 'Home',
        components: {
            DomicileSearch: defineAsyncComponent(() => import('../components/Domicile.Code.Search.vue')),
            JurisdictionSearch: defineAsyncComponent(() => import('../components/Jurisdiction.Code.Search.vue')),
            ParishList: defineAsyncComponent(() => import('../components/Parish.List.vue')),
            RecentTaxChanges: defineAsyncComponent(() => import('../components/Recent.Tax.Changes.vue'))
        },
        data: function () {
            return {
                data: {}
            };
        },
        methods: {
        },
        created: function () {
        },
        mounted: function () {  
        }
    }
</script>
